import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { CLEAR_CACHE_STORAGE_KEY, FIREBASE_FUNCTIONS_REGION } from './GlobalConstants';

let config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

console.log('current environment :', process.env.REACT_APP_ENV);

// Initialize both app and static environments
const app = firebase.initializeApp(config);
const firestoreSettings: firebase.firestore.Settings = {
  // Default firestore settings
  ignoreUndefinedProperties: true,
  // cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED // DONT USE, EVIL
  // cacheSizeBytes: 1048576 * 10 // 10MB
  cacheSizeBytes: 1048576 * 100,
  merge: true, // This line is required for the Firestore emulator to work properly. Don't delete
};

firestoreSettings.experimentalAutoDetectLongPolling = true;

if (localStorage.getItem(CLEAR_CACHE_STORAGE_KEY)) {
  console.log('CLEARING FIRESTORE CACHE');
  app.firestore().clearPersistence();
  localStorage.removeItem(CLEAR_CACHE_STORAGE_KEY);
}

[app].forEach((fb) => fb.firestore().settings(firestoreSettings));

app
  .firestore()
  .enablePersistence({
    synchronizeTabs: false,
    // experimentalForceOwningTab: true,
  })
  .catch(function (err) {
    console.error('Error while initializing firestore offline persistence:', err);
    if (err.code === 'failed-precondition') {
      // when this error code is thrown, it means the app is already open in a different tab
    }
  });

export default firebase;
export const firestore = app.firestore();
export const functions = app.functions(FIREBASE_FUNCTIONS_REGION);
export const storage = app.storage();
export const auth = getAuth(app);
