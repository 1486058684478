  import * as Sentry from '@sentry/react';
import {
  IonBadge,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToggle,
  IonToolbar,
  IonModal,
  IonRouterLink,
} from '@ionic/react';
import {
  alertCircleOutline,
  beerOutline,
  cloudOfflineOutline,
  cloudUploadOutline,
  cogOutline,
  documentAttachOutline,
  earthOutline,
  imagesOutline,
  logOutOutline,
  mailOutline,
  mailUnreadOutline,
  moonOutline,
  newspaperOutline,
  pieChartOutline,
  readerOutline,
  syncOutline,
  sunnyOutline,
  exitOutline,
  codeSlashOutline,
} from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ctxOrg, ctxProfile } from '../App';
import { auth, firestore } from '../ConfigFirebase';
import { alertController } from '@ionic/core';
import { logout, triggerAppUpdate } from '../DataApp';
import { preloadKnownOfflineData, preloadOfflineData } from '../DataStorage';
import {
  AppProduct,
  InsightsTierAction,
  QCTierAction,
  isFreeQCTier,
  isTestAccount,
  orgHasPermission,
  userHasPermission,
} from '../PermissionsService';
import i18n from '../ServiceI18n';
import { toastController } from '@ionic/core';
import './CSideMenu.scss';
import { doImageSync } from '../DataImage';
import { DARK_MODE_STORAGE_KEY } from '../GlobalConstants';
import { Capacitor } from '@capacitor/core';
import CProgressBar from './CProgressBar';
import { ImagesState, OfflineSyncingStatus, PreloadDataEntities } from '../Model';
import { presentStandardToast } from '../HelperIonic';
import { ModalOfflineEntitySelection } from '../modals/ModalOfflineEntitySelection';
import {
  applicationStateObservable,
  imagesStateObservable,
} from '../simpleObservable/observables';
import { CAppVersion } from './CAppVersion';

// ----------------------------------------------------------------
// SideMenu
// ----------------------------------------------------------------

export const ImageSync = ({ renderAs, fontColor = 'white' }) => {
  const [imgState, setImagesUpdateState] = useState(imagesStateObservable.getValue());

  useEffect(() => {
    let isCancelled = false;
    const unsubscribe = imagesStateObservable.subscribe((data: ImagesState) => {
      if (isCancelled) return;
      setImagesUpdateState(data);
    });
    return () => {
      isCancelled = true;
      unsubscribe();
    };
  }, []);

  switch (renderAs) {
    case 'item':
      if (imgState.images.length === 0) return null;
      return (
        <IonItem
          button
          detail={false}
          data-tip={`${imgState.images.length} pictures to upload`}
          className="c-image-sync"
          onClick={() => {
            if (imgState.syncing) {
              return;
            }
            doImageSync(true);
          }}
        >
          <IonIcon
            icon={imgState.syncing ? syncOutline : imagesOutline}
            className={imgState.syncing ? 'rotate' : ''}
            slot="start"
          />
          <IonLabel>{imgState.images.length} Pictures to upload</IonLabel>
          {/* {inboxUnreadCount > 0 && <IonBadge mode="ios" slot="end" color="danger">{inboxUnreadCount}</IonBadge>} */}
        </IonItem>
      );

    case 'button':
      if (imgState.images.length === 0) return null;
      return (
        <IonButton
          className="c-image-sync"
          color={fontColor ?? 'white'}
          onClick={() => {
            if (imgState.syncing) {
              return;
            }
            doImageSync(true);
          }}
        >
          <IonIcon
            icon={imgState.syncing ? syncOutline : imagesOutline}
            className={imgState.syncing ? 'rotate' : ''}
            slot="start"
          />
          <IonLabel>{imgState.images.length} Pictures to upload</IonLabel>
          {/* {inboxUnreadCount > 0 && <IonBadge mode="ios" slot="end" color="danger">{inboxUnreadCount}</IonBadge>} */}
        </IonButton>
      );

    case 'icon':
      if (!imgState.syncing) return null;
      if (imgState.images.length === 0) return null;
      return (
        <>
          {/* position: absolute;
        margin: 29px 0px 0 13px;
        background: white;
        z-index: 4;
        font-size: 12px;
        border-radius: 10px;
        font-weight: bold; */}

          {/* <div style={{position: 'absolute'}}>{imgState.images.length + 15}</div> */}
          <IonIcon
            icon={syncOutline}
            className={'c-image-sync rotate'}
            // onClick={_ => menuController.open()}
          />
        </>
      );
  }
};

// ----------------------------------------------------------------
// SideMenu
// ----------------------------------------------------------------

const SM = () => {
  useEffect(() => {
    const unsubscribe = applicationStateObservable.subscribe((data) => {
      setUpdateState(data.updateAvailable);
    });
    return () => unsubscribe();
  }, []);

  const initialDarkMode = window.localStorage.getItem(DARK_MODE_STORAGE_KEY);

  const menuRef = useRef(null);
  const [loadingOfflineCache, setLoadingOfflineCache] = useState(false);
  const [url] = useState('' /*window.location.href*/);
  const [darkMode, setDarkMode] = useState(initialDarkMode);
  const [isUpdateState, setUpdateState] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);
  const [onlineMode, setOnlineMode] = useState(true);

  // Offline
  const [syncingStatus, setSyncingStatus] = useState<OfflineSyncingStatus>({
    entity: 'data',
    progress: 0,
  });
  const [showOfflineModal, setShowOfflineModal] = useState<boolean>(false);

  // --------------------------------------------------------------
  // contexts
  // --------------------------------------------------------------
  const org = useContext(ctxOrg);
  const isFreeTier = isFreeQCTier(org?.settings);
  const profile = useContext(ctxProfile);
  const inboxUnreadCount = profile?.unreadConversationsCount ?? 0;

  // --------------------------------------------------------------
  // permissions
  // --------------------------------------------------------------
  const showDashboard =
    userHasPermission(profile, 'VIEW', 'DASHBOARD') &&
    orgHasPermission(org?.settings, AppProduct.QualityControl, QCTierAction.Dashboard);

  const showReports = userHasPermission(profile, 'VIEW', 'REPORT');

  const showQC =
    !isFreeTier &&
    (userHasPermission(profile, 'VIEW', 'ORDER') ||
      userHasPermission(profile, 'VIEW', 'LOT'));

  const showInbox = false
    !isFreeTier &&
    orgHasPermission(org?.settings, AppProduct.QualityControl, QCTierAction.Chat);

  const showPartners =
    !isFreeTier &&
    (userHasPermission(profile, 'VIEW', 'ORDER') ||
      userHasPermission(profile, 'VIEW', 'INSIGHT_PROFILES'));

  const showInsights =
    !isFreeTier &&
    userHasPermission(profile, 'VIEW', 'INSIGHT_EXPLORE') &&
    orgHasPermission(org?.settings, AppProduct.Insights, InsightsTierAction.Insights);

  const doLogout = async () => {
    await logout(auth);
    // window.location.href = '/';
  };

  const toggleDarkMode = (e) => {
    e.preventDefault();
    document.body.classList.toggle('dark');
    window.localStorage.setItem(
      DARK_MODE_STORAGE_KEY,
      darkMode === 'true' ? 'false' : 'true'
    );
    setDarkMode(darkMode === 'true' ? 'false' : 'true');
  };

  //--------------------------------------------------
  // OFFLINE FUNCTIONALITY

  const goOffline = async () => {
    if (loadingOfflineCache) return;

    // if already offline, disable offline mode
    if (!onlineMode) return toggleOfflineMode();

    const alert = await alertController.create({
      header: 'Do you know the IDs of the batches/orders you will inspect?',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            setShowOfflineModal(true);
          },
        },
        {
          text: 'No',
          cssClass: 'alert-button neutral-button',
          handler: () => {
            toggleOfflineMode();
          },
        },
      ],
    });

    alert.present();
  };

  const toggleOfflineMode = async (payload?: PreloadDataEntities) => {
    if (loadingOfflineCache) return;
    try {
      if (onlineMode) {
        setLoadingOfflineCache(true);

        if (!!payload) {
          await preloadKnownOfflineData(
            firestore,
            profile.organisationId,
            payload,
            setSyncingStatus
          );
        } else {
          await preloadOfflineData(
            firestore,
            profile.organisationId,
            undefined,
            setSyncingStatus
          );
        }

        await firestore.disableNetwork();

        applicationStateObservable.next({
          ...applicationStateObservable.getValue(),
          networkEnabled: false,
        });

        setOnlineMode(false);
        setLoadingOfflineCache(false);

        await presentStandardToast(
          toastController,
          'Data has been loaded, you can now work offline!',
          3000,
          'dark'
        );
      } else {
        setLoadingOfflineCache(true);

        await firestore.enableNetwork();

        applicationStateObservable.next({
          ...applicationStateObservable.getValue(),
          networkEnabled: true,
        });

        await presentStandardToast(
          toastController,
          'You are back online! wait till data has been synced',
          3000,
          'dark'
        );

        await firestore.waitForPendingWrites();

        await presentStandardToast(
          toastController,
          'Data has been synced successfully',
          5000,
          'primary',
          'bottom'
        );

        setLoadingOfflineCache(false);
        setOnlineMode(true);
      }
    } catch (e) {
      Sentry.captureException(e);
      setLoadingOfflineCache(false);
      setOnlineMode(true);

      await presentStandardToast(
        toastController,
        'There was an error while trying to download cache data\n\n' + e,
        3000,
        'danger2',
        'top',
        'error-toast'
      );
    }
  };

  let closeMenu = () => {
    menuRef.current.close();
  };

  const toggleMenu = () => {
    if (document.body.clientWidth < 992) {
      return;
    }
    setCollapsed(!isCollapsed);
    setTimeout((_) => window.dispatchEvent(new Event('resize')), 100);
  };

  // ReactTooltip.rebuild()

  const isThatUrl = document.location.href.includes('login.agrinorm.support');

  if (isThatUrl) {
    return null;
  }

  return (
    <>
      <IonMenu
        ref={menuRef}
        // type="push"
        side="start"
        id="main-menu"
        contentId="mainRouter"
        className={`no-print ${isCollapsed && 'menu-collapsed'}`}
        // disabled={true}
        // disable swipe to show side-menu
        maxEdgeStart={0}
      >
        <IonHeader>
          <IonToolbar onClick={toggleMenu}>
            <IonTitle>agrinorm</IonTitle>
            <img
              src="/assets/icon/android-icon-192x192.png"
              className="logo"
              alt="agrinorm"
            />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Agrinorm</IonTitle>
          </IonToolbar>
        </IonHeader> */}
          <IonList>
            {isUpdateState && (
              <IonItem
                detail={false}
                color="danger2"
                className="update-menu-item"
                onClick={() => triggerAppUpdate(false)}
              >
                <IonIcon icon={alertCircleOutline} slot="start" />
                <IonLabel>
                  <b>UPDATE AVAILABLE</b>
                </IonLabel>
              </IonItem>
            )}

            <ImageSync renderAs="item" />

            {showDashboard && (
              <IonItem
                detail={false}
                data-tip="Commercial Dashboard"
                routerLink="/tabs/dashboard"
                onClick={() => closeMenu()}
                className={url.indexOf('/dashboard') >= 0 ? 'selected' : ''}
              >
                <IonIcon icon={newspaperOutline} slot="start" />
                <IonLabel>Dashboard</IonLabel>
              </IonItem>
            )}

            {showQC && (
              <IonItem
                detail={false}
                data-tip="Quality Control"
                routerLink="/tabs/quality-control"
                onClick={() => closeMenu()}
                className={url.indexOf('/quality-control') >= 0 ? 'selected' : ''}
              >
                <IonIcon icon={readerOutline} slot="start" />
                <IonLabel>Quality Control</IonLabel>
              </IonItem>
            )}

            {showReports && (
              <IonItem
                detail={false}
                data-tip="Reports"
                routerLink="/tabs/reports"
                onClick={() => closeMenu()}
                className={url.indexOf('/reports') >= 0 ? 'selected' : ''}
              >
                <IonIcon icon={documentAttachOutline} slot="start" />
                <IonLabel>Reports</IonLabel>
              </IonItem>
            )}

            {showInbox && (
              <IonItem
                detail={false}
                data-tip="Inbox"
                routerLink="/tabs/inbox"
                onClick={() => closeMenu()}
                className={url.indexOf('/inbox') >= 0 ? 'selected' : ''}
              >
                <IonIcon
                  icon={inboxUnreadCount > 0 ? mailUnreadOutline : mailOutline}
                  slot="start"
                />
                <IonLabel>{i18n.t('Tabs.inbox')}</IonLabel>
                {inboxUnreadCount > 0 && (
                  <IonBadge mode="ios" slot="end" color="danger">
                    {inboxUnreadCount}
                  </IonBadge>
                )}
              </IonItem>
            )}

            {/* Since "partners" shows orders, we don't allow people to go in without view order permissions */}
            {showPartners && (
              <IonItem
                detail={false}
                data-tip={i18n.t('Tabs.connect')}
                routerLink="/tabs/connect"
                onClick={() => closeMenu()}
                className={url.indexOf('/connect') >= 0 ? 'selected' : ''}
              >
                <IonIcon icon={earthOutline} slot="start" />
                <IonLabel>{i18n.t('Tabs.connect')}</IonLabel>
              </IonItem>
            )}

            {showInsights && (
              <IonRouterLink
                href={`${process.env.REACT_APP_NEW_PLATFORM_HOST}/insights`}
                target="_blank"
                rel="noreferrer"
              >
                <IonItem
                  detail={false}
                  data-tip="Insights"
                  className={url.indexOf('insight') >= 0 ? 'selected' : ''}
                  onClick={() => closeMenu()}
                  button
                >
                  <IonIcon icon={pieChartOutline} slot="start" />
                  <IonLabel>Insights</IonLabel>
                  <IonIcon icon={exitOutline} slot="end" />
                </IonItem>
              </IonRouterLink>
            )}

            {!isFreeTier && (
              <IonItem
                detail={false}
                onClick={goOffline}
                button
                className={`show-only-mobile ${loadingOfflineCache ? 'loading' : ''}`}
              >
                {!loadingOfflineCache ? (
                  onlineMode ? (
                    <IonIcon icon={cloudOfflineOutline} slot="start" />
                  ) : (
                    <IonIcon icon={cloudUploadOutline} slot="start" />
                  )
                ) : (
                  <IonIcon icon={syncOutline} className="rotate" slot="start" />
                )}
                <IonLabel>
                  {onlineMode ? (
                    <>
                      <>{`${loadingOfflineCache ? 'GOING' : 'GO'} OFFLINE`}</>
                      {loadingOfflineCache && (
                        <CProgressBar percentage={syncingStatus.progress} />
                      )}
                    </>
                  ) : (
                    <>
                      OFFLINE MODE <b>ON</b>
                      <p>click here to go online</p>
                    </>
                  )}
                  {loadingOfflineCache && (
                    <p style={{ animation: 'fadeInUp .3s both' }}>
                      {`syncing ${syncingStatus.entity}, please wait...`}
                    </p>
                  )}
                </IonLabel>
              </IonItem>
            )}
          </IonList>

          <div className="button-container">
            <IonList>
              {(userHasPermission(profile, 'WRITE', 'ADMIN_PARTNERS') ||
                userHasPermission(profile, 'WRITE', 'ADMIN') ||
                userHasPermission(profile, 'WRITE', 'SPECIFICATIONS')) && (
                <IonItem
                  detail={false}
                  data-tip="Admin"
                  routerLink="/tabs/admin"
                  onClick={() => closeMenu()}
                  className={`hide-only-mobile ${
                    url.indexOf('/admin') >= 0 ? 'selected' : ''
                  }`}
                >
                  <IonIcon icon={beerOutline} slot="start" />
                  <IonLabel>Admin</IonLabel>
                </IonItem>
              )}
              <IonItem
                detail={false}
                data-tip="Settings"
                routerLink="/tabs/settings"
                onClick={() => closeMenu()}
                className={url.indexOf('/profile') >= 0 ? 'selected' : ''}
              >
                <IonIcon icon={cogOutline} slot="start" />
                <IonLabel>
                  Settings
                  <br />
                  <p>{profile.email}</p>
                  {isTestAccount(org?.settings) && (
                    <IonBadge color="danger2">TEST ACCOUNT</IonBadge>
                  )}
                </IonLabel>
              </IonItem>

              <IonItem detail={false} onClick={doLogout} data-tip="Logout" button>
                <IonIcon icon={logOutOutline} slot="start" />
                <IonLabel>{i18n.t('PageMain.logout')}</IonLabel>
              </IonItem>

              <IonItem
                detail={false}
                routerLink="/secure/changelog"
                onClick={() => closeMenu()}
              >
                <IonIcon icon={codeSlashOutline} slot="start" />
                <IonLabel>
                  <CAppVersion />
                  {/* Native apps refer to the Capacitor web frame via localhost */}
                  {(process.env.REACT_APP_ENV !== 'production' ||
                    (window.location.hostname === 'localhost' &&
                      !Capacitor.isNativePlatform())) && (
                    <p className={'enviroment ' + process.env.REACT_APP_ENV}>
                      {process.env.REACT_APP_ENV}
                    </p>
                  )}
                </IonLabel>
              </IonItem>

              <IonItem
                detail={false}
                data-tip="Dark Mode"
                onClick={toggleDarkMode}
                button
              >
                <IonIcon icon={moonOutline} slot="start" className="darkmode-sunny" />
                <IonIcon icon={sunnyOutline} slot="start" className="darkmode-moon" />
                <IonLabel>
                  Dark Mode
                  {/* <IonBadge color="dark">
                  <span className="darkmode-sunny">ON</span>  
                  <span className="darkmode-moon">OFF</span>
                </IonBadge> */}
                </IonLabel>
                <IonToggle slot="end" checked={darkMode === 'true'} />
              </IonItem>
            </IonList>
          </div>

          {showOfflineModal && (
            <IonModal
              isOpen={showOfflineModal}
              cssClass="modal-page-new-lot"
              onDidDismiss={() => setShowOfflineModal(false)}
            >
              <ModalOfflineEntitySelection
                onCancel={() => setShowOfflineModal(false)}
                onAccept={(data) => {
                  toggleOfflineMode(data);
                  setShowOfflineModal(false);
                }}
              />
            </IonModal>
          )}
        </IonContent>
      </IonMenu>
      {/* {isCollapsed && <ReactTooltip
      place="right"
      effect="solid"
      backgroundColor="var(--ion-color-dark)"
      textColor="var(--ion-color-light)"
    />} */}
    </>
  );
};

export const CSideMenu = React.memo(SM);
